* {
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: 0;
}

html {
  min-height: 100%;
  overflow-x: hidden;
  width: 100%;
}

body {
  @include font-smoothing;
  background-color: $color-white;
  color: $color-gray;
  -webkit-text-size-adjust: 100%;
  font-family: $font-primary;
  font-size: $font-normal;
  line-height: 1.25;
  min-height: 100%;
  overflow-x: hidden;
  width: 100%;
}

[unselectable] {
	-webkit-touch-callout: none;
	user-select: none;
}

a {
	color: $color-green;
	opacity: 1;
	transition: all $transition-default;

	&:hover {
		opacity: .75;
	}

	&:visited,
	&:active {
		color: $color-green;
	}
}

img, media, svg {
  display: block;
  height: auto;
  max-width: 100%;

  &.svg-symbols {
    display: none;
  }
}

ul, ol {
  margin: 0;
}

/* Chrome autocomplete form fix */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

.main-wrapper{
  height: 100dvh;
  width: 100vw;

  .main{
    height: 100%;
    width: 100%;
  }
}