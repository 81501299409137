.section--landing{
    height: 100%;
    width: 100%;

    .container{
        height: 100%;
        position: relative;
        width: 100%;

        .background-image{
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;

            img{
                height: 100%;
                object-fit: cover;
                width: 100%;

                @include mq(md){
                    display: none;
                }

                &.desktop{
                    display: none;

                    @include mq(md){
                        display: block;
                    }
                }
            }
        }

        .text{
            bottom: pxrem(120);
            height: 25vh;
            left: 10vw;
            position: absolute;
            width: 80vw;
            
            @include mq(md){
                height: 25vh;
                bottom: pxrem(174);
                width: 60.5555vw;
                left: 19.7222vw;
            }

            .desktop{
                display: none;

                @include mq(md){
                    display: block;
                }
            }

            .mobile{
                // display: block;

                @include mq(md){
                    display: none;
                }
            }
        }

        .banner{
            align-items: center;
            background-color: $color-white;
            bottom: pxrem(30);
            display: flex;
            justify-content: space-between;
            left: 0;
            padding: pxrem(14);
            position: absolute;
            width: 100%;

            @include mq(md){
                padding: pxrem(29) 6.9444vw;
            }

            img{
                height: auto;
                width: pxrem(92);

                @include mq(md){
                    width: pxrem(184);
                }
            }

            p{
                color: $color-red;
                font-size: pxrem(14);
                margin: 0;

                &.strong{
                    font-weight: 800;
                }

                @include mq(md){
                    font-size: pxrem(16);
                }
            }
        }
    }
}