.icon {
	display: block;
	flex-shrink: 0;
	pointer-events: none;

	// Icon transformations
	&.icon--rotate-45 {
		transform: rotate(45deg);
	}

	&.icon--rotate-90 {
		transform: rotate(90deg);
	}

	&.icon--rotate-180 {
		transform: rotate(180deg);
	}

	&.icon--rotate-270 {
		transform: rotate(270deg);
	}

	// Icon colors
	@each $name, $value in $colors-map {
    &.icon--#{$name}-fill {
      @include icon-fill($value);
    }

		&.icon--#{$name}-stroke {
      @include icon-stroke($value);
    }
  }

	// Custom icons
	&.icon--name {
		height: pxrem(44);
		width: pxrem(120);

		@include mq(md) {
			height: pxrem(60);
			width: pxrem(160);
		}
	}

	&.icon--logo{
		height: pxrem(32);
		width: pxrem(91);

		@include mq(md){
			height: pxrem(61);
			width: pxrem(184);
		}
	}

	&.icon--testo{
		height: 100%;
		width: 100%;
	}
}