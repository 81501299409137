// =============================================================================
// Sizes
// =============================================================================

// Max widths and fixed components heights
$size-maxwidth:         1300px;

$header-height:         50px;
$header-height-small:   35px;

// =============================================================================
// Colors
// =============================================================================

// Brand colors
$color-green:           #C8D100;
$color-green-dark:      darken(#C8D100, 15);

// Neutral colors
$color-white:           #FFFFFF;
$color-red:             #CE0E2D;
$color-gray-light:      #F9F9F9;
$color-gray:            #5C5C5C;
$color-gray-dark:       #333333;
$color-black:           #000000;

$colors-map: (
  "green": $color-green,
  "green-dark": $color-green-dark,
  "white": $color-white,
  "gray-light": $color-gray-light,
  "gray": $color-gray,
  "gray-dark": $color-gray-dark,
  "black": $color-black,
);


// =============================================================================
// Typography
// =============================================================================

$font-primary: 'Raleway', sans-serif;
$font-secondary:  Georgia, Times, 'Times New Roman', serif;

$font-xsmall:       pxrem(13);
$font-small:        pxrem(14);
$font-normal:       pxrem(16);
$font-medium:       pxrem(18);
$font-discrete:     pxrem(24);
$font-large:        pxrem(32);
$font-xlarge:       pxrem(38);


// =============================================================================
// Transitions
// =============================================================================

$transition-default: 250ms ease-in-out;