* {
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: 0;
}

html {
  min-height: 100%;
  overflow-x: hidden;
  width: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
  color: #5C5C5C;
  -webkit-text-size-adjust: 100%;
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  line-height: 1.25;
  min-height: 100%;
  overflow-x: hidden;
  width: 100%;
}

[unselectable] {
  -webkit-touch-callout: none;
  user-select: none;
}

a {
  color: #C8D100;
  opacity: 1;
  transition: all 250ms ease-in-out;
}
a:hover {
  opacity: 0.75;
}
a:visited, a:active {
  color: #C8D100;
}

img, media, svg {
  display: block;
  height: auto;
  max-width: 100%;
}
img.svg-symbols, media.svg-symbols, svg.svg-symbols {
  display: none;
}

ul, ol {
  margin: 0;
}

/* Chrome autocomplete form fix */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.main-wrapper {
  height: 100dvh;
  width: 100vw;
}
.main-wrapper .main {
  height: 100%;
  width: 100%;
}

.h-hide {
  display: none !important;
  visibility: hidden;
}

.h-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.h-noscroll {
  overflow: hidden;
}

.icon {
  display: block;
  flex-shrink: 0;
  pointer-events: none;
}
.icon.icon--rotate-45 {
  transform: rotate(45deg);
}
.icon.icon--rotate-90 {
  transform: rotate(90deg);
}
.icon.icon--rotate-180 {
  transform: rotate(180deg);
}
.icon.icon--rotate-270 {
  transform: rotate(270deg);
}
.icon.icon--green-fill {
  fill: #C8D100;
}
.icon.icon--green-fill circle,
.icon.icon--green-fill path,
.icon.icon--green-fill polygon,
.icon.icon--green-fill rect {
  fill: #C8D100;
}
.icon.icon--green-stroke {
  fill: none;
  stroke: #C8D100;
}
.icon.icon--green-stroke circle,
.icon.icon--green-stroke path,
.icon.icon--green-stroke polygon,
.icon.icon--green-stroke rect {
  fill: none;
  stroke: #C8D100;
}
.icon.icon--green-dark-fill {
  fill: #7f8500;
}
.icon.icon--green-dark-fill circle,
.icon.icon--green-dark-fill path,
.icon.icon--green-dark-fill polygon,
.icon.icon--green-dark-fill rect {
  fill: #7f8500;
}
.icon.icon--green-dark-stroke {
  fill: none;
  stroke: #7f8500;
}
.icon.icon--green-dark-stroke circle,
.icon.icon--green-dark-stroke path,
.icon.icon--green-dark-stroke polygon,
.icon.icon--green-dark-stroke rect {
  fill: none;
  stroke: #7f8500;
}
.icon.icon--white-fill {
  fill: #FFFFFF;
}
.icon.icon--white-fill circle,
.icon.icon--white-fill path,
.icon.icon--white-fill polygon,
.icon.icon--white-fill rect {
  fill: #FFFFFF;
}
.icon.icon--white-stroke {
  fill: none;
  stroke: #FFFFFF;
}
.icon.icon--white-stroke circle,
.icon.icon--white-stroke path,
.icon.icon--white-stroke polygon,
.icon.icon--white-stroke rect {
  fill: none;
  stroke: #FFFFFF;
}
.icon.icon--gray-light-fill {
  fill: #F9F9F9;
}
.icon.icon--gray-light-fill circle,
.icon.icon--gray-light-fill path,
.icon.icon--gray-light-fill polygon,
.icon.icon--gray-light-fill rect {
  fill: #F9F9F9;
}
.icon.icon--gray-light-stroke {
  fill: none;
  stroke: #F9F9F9;
}
.icon.icon--gray-light-stroke circle,
.icon.icon--gray-light-stroke path,
.icon.icon--gray-light-stroke polygon,
.icon.icon--gray-light-stroke rect {
  fill: none;
  stroke: #F9F9F9;
}
.icon.icon--gray-fill {
  fill: #5C5C5C;
}
.icon.icon--gray-fill circle,
.icon.icon--gray-fill path,
.icon.icon--gray-fill polygon,
.icon.icon--gray-fill rect {
  fill: #5C5C5C;
}
.icon.icon--gray-stroke {
  fill: none;
  stroke: #5C5C5C;
}
.icon.icon--gray-stroke circle,
.icon.icon--gray-stroke path,
.icon.icon--gray-stroke polygon,
.icon.icon--gray-stroke rect {
  fill: none;
  stroke: #5C5C5C;
}
.icon.icon--gray-dark-fill {
  fill: #333333;
}
.icon.icon--gray-dark-fill circle,
.icon.icon--gray-dark-fill path,
.icon.icon--gray-dark-fill polygon,
.icon.icon--gray-dark-fill rect {
  fill: #333333;
}
.icon.icon--gray-dark-stroke {
  fill: none;
  stroke: #333333;
}
.icon.icon--gray-dark-stroke circle,
.icon.icon--gray-dark-stroke path,
.icon.icon--gray-dark-stroke polygon,
.icon.icon--gray-dark-stroke rect {
  fill: none;
  stroke: #333333;
}
.icon.icon--black-fill {
  fill: #000000;
}
.icon.icon--black-fill circle,
.icon.icon--black-fill path,
.icon.icon--black-fill polygon,
.icon.icon--black-fill rect {
  fill: #000000;
}
.icon.icon--black-stroke {
  fill: none;
  stroke: #000000;
}
.icon.icon--black-stroke circle,
.icon.icon--black-stroke path,
.icon.icon--black-stroke polygon,
.icon.icon--black-stroke rect {
  fill: none;
  stroke: #000000;
}
.icon.icon--name {
  height: 2.75rem;
  width: 7.5rem;
}
@media (min-width: 48em) {
  .icon.icon--name {
    height: 3.75rem;
    width: 10rem;
  }
}
.icon.icon--logo {
  height: 2rem;
  width: 5.6875rem;
}
@media (min-width: 48em) {
  .icon.icon--logo {
    height: 3.8125rem;
    width: 11.5rem;
  }
}
.icon.icon--testo {
  height: 100%;
  width: 100%;
}

.section--landing {
  height: 100%;
  width: 100%;
}
.section--landing .container {
  height: 100%;
  position: relative;
  width: 100%;
}
.section--landing .container .background-image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.section--landing .container .background-image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
@media (min-width: 48em) {
  .section--landing .container .background-image img {
    display: none;
  }
}
.section--landing .container .background-image img.desktop {
  display: none;
}
@media (min-width: 48em) {
  .section--landing .container .background-image img.desktop {
    display: block;
  }
}
.section--landing .container .text {
  bottom: 7.5rem;
  height: 25vh;
  left: 10vw;
  position: absolute;
  width: 80vw;
}
@media (min-width: 48em) {
  .section--landing .container .text {
    height: 25vh;
    bottom: 10.875rem;
    width: 60.5555vw;
    left: 19.7222vw;
  }
}
.section--landing .container .text .desktop {
  display: none;
}
@media (min-width: 48em) {
  .section--landing .container .text .desktop {
    display: block;
  }
}
@media (min-width: 48em) {
  .section--landing .container .text .mobile {
    display: none;
  }
}
.section--landing .container .banner {
  align-items: center;
  background-color: #FFFFFF;
  bottom: 1.875rem;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 0.875rem;
  position: absolute;
  width: 100%;
}
@media (min-width: 48em) {
  .section--landing .container .banner {
    padding: 1.8125rem 6.9444vw;
  }
}
.section--landing .container .banner img {
  height: auto;
  width: 5.75rem;
}
@media (min-width: 48em) {
  .section--landing .container .banner img {
    width: 11.5rem;
  }
}
.section--landing .container .banner p {
  color: #CE0E2D;
  font-size: 0.875rem;
  margin: 0;
}
.section--landing .container .banner p.strong {
  font-weight: 800;
}
@media (min-width: 48em) {
  .section--landing .container .banner p {
    font-size: 1rem;
  }
}